<template>
  <div>
    <validation-observer
      v-slot="{ invalid }"
    >
      <b-row>
        <b-col class="settings-title" cols="10">
          {{ $t('views.client.details.body.right.rib.title') }}
        </b-col>
        <b-col v-if="!editMode" align="right" cols="2">
          <i
            @click="editMode = !editMode"
            class="d-icon-gray icofont icofont-ui-edit pointer"
          />
        </b-col>
        <b-col v-else align="right" cols="2">
          <i
            @click="onSave(invalid)"
            class="d-icon-gray btn-check-size icofont icofont-check pointer"
          />
          <i
            @click="onAbort"
            class="d-icon-gray btn-close-size icofont icofont-close pointer"
          />
        </b-col>
      </b-row>
      <div
        v-if="false === editMode"
      >
        <b-row class="mt-5">
          <b-col
            class="gray-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
            :cols="getCols(2)"
          >
            {{ $t('views.client.details.body.right.rib.iban') }}
          </b-col>
          <b-col
            class="black-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1':''"
          >
            {{ bankConfiguration.iban ? bankConfiguration.iban : $t('general.actions.undefined') }}
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            class="gray-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
            :cols="getCols(2)"
          >
            {{ $t('views.client.details.body.right.rib.bic') }}
          </b-col>
          <b-col
            class="black-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1':''"
          >
            {{ bankConfiguration.bic ? bankConfiguration.bic : $t('general.actions.undefined') }}
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            class="gray-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
            :cols="getCols(2)"
          >
            {{ $t('views.client.details.body.right.rib.ics') }}
          </b-col>
          <b-col
            class="black-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1':''"
          >
            {{ bankConfiguration.ics ? bankConfiguration.ics : $t('general.actions.undefined') }}
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row class="mt-5">
          <b-col
            class="gray-col-title"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
            :cols="getCols(2)"
          >
            {{ $t('views.client.details.body.right.rib.iban') }}
          </b-col>
          <b-col>
            <d-text-field
              v-model="bankConfiguration.iban"
              size="sm"
              class-name="background-light-blue-inputs form-control-sm"
              :rules="validation.iban"
            />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
            :cols="getCols(2)"
            class="gray-col-title"
          >
            {{ $t('views.client.details.body.right.rib.bic') }}
          </b-col>
          <b-col>
            <d-text-field
              v-model="bankConfiguration.bic"
              size="sm"
              class-name="background-light-blue-inputs form-control-sm"
              :rules="validation.bic"
            />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            :cols="getCols(2)"
            :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
            class="gray-col-title"
          >
            {{ $t('views.client.details.body.right.rib.ics') }}
          </b-col>
          <b-col>
            <d-text-field
              v-model="bankConfiguration.ics"
              size="sm"
              class-name="background-light-blue-inputs form-control-sm"
              :rules="validation.ics"
            />
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import {SUCCESS} from "@plugins/flash";
import {_delete, _get, _put} from "@api/doinsport/services/httpService";
import {postClubBankConfig} from "@api/doinsport/services/club/club-bank-config.api";

export default {
  props: {
    club: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/BankConfiguration.json'),
    },
  },
  data: () => ({
    editMode: false,
    bankConfiguration: {
      iban: null,
      bic: null,
      ics: null
    }
  }),
  components: {},
  computed: {
    getImgDim() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 767) {
        return '110';
      }
      return '145'
    },
  },
  methods: {
    isEmpty(field) {
      return field === '' || field === null;
    },
    reloadClubInstance() {
      this.$emit('on:reload-club');
    },
    onSave(invalid) {
      if (invalid) {
        if (this.club.bankConfiguration) {
          if (this.isEmpty(this.bankConfiguration.iban) && this.isEmpty(this.bankConfiguration.ics) && this.isEmpty(this.bankConfiguration.bic)) {
            _delete(this.club.bankConfiguration['@id'])
              .then((response) => {
                this.initBankConfiguration({
                  iban: null,
                  ics: null,
                  bic: null
                });
                this.club.bankConfiguration = null;
                this.editMode = !this.editMode;
                this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
              })
              .finally(() => {
                this.$store.dispatch('auth/getSelectedClub');
              })
            ;
          } else {
            this.$flash(this.$t('general.actions.error'), this.$t('validation.check-fields'));
          }
        } else {
          this.$flash(this.$t('general.actions.error'), this.$t('validation.check-fields'));
        }
      } else {
        this.getBankConfigRequest(this.club.bankConfiguration, this.bankConfiguration)
          .then((response) => {
            this.initBankConfiguration(response.data);
            this.club.bankConfiguration = response.data;
            this.editMode = !this.editMode;
            this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
          })
          .finally(() => {
            this.$store.dispatch('auth/getSelectedClub');
          })
        ;
      }
    },
    getBankConfigRequest(bankConfiguration, config) {
      if (bankConfiguration) {
        return _put(bankConfiguration['@id'], config);
      } else {
        config.club = this.$store.getters["auth/currentClub"]['@id'];

        return postClubBankConfig(config);
      }
    },
    onAbort() {
      this.editMode = !this.editMode;
      this.$store.dispatch('validator/clear', this.validation);
      this.loadBankConfiguration();
    },
    getCols(x) {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1967 && width >= 767) {
        return 3
      } else if (width < 767) {
        return 12;
      } else {
        return x;
      }
    },
    initBankConfiguration(data) {
      this.bankConfiguration.iban = data.iban;
      this.bankConfiguration.bic = data.bic;
      this.bankConfiguration.ics = data.ics;
    },
    loadBankConfiguration() {
      if (this.club.bankConfiguration) {
        _get(this.club.bankConfiguration['@id'])
          .then((response) => {
            this.initBankConfiguration(response.data);
          })
        ;
      }
    }
  },
  created() {
    this.loadBankConfiguration();
  },
  beforeMount() {
    this.$store.dispatch('validator/set', this.validation);
  }
}
</script>
<style lang="scss" scoped>
.img-margins {
  margin-top: 20%;
}

.btn-close-size {
  font-size: 20px;
}

.btn-check-size {
  font-size: 22px;
}
</style>
