<template>
  <div class="container-fluid">
    <b-card>
      <div class="wrapper">
        <div class="mt-2 mb-3">
          <b-row>
            <b-col cols="5">
              <bank-details
                v-if="club.id"
                :club="club"
              />
            </b-col>
            <div
              v-if="$store.getters['layout/getInnerWidth'] >= 962"
              class="split-layout__divider"
            >
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <div
              v-if="getTimeTableCols() !== 5"
              class="split-layout__divider"
            >
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col class="pl-5" cols="6">
              <club-services
                :club="club"
                @on:reload-club="$emit('on:reload-club')"
                classContainer="ml-0"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import BankDetails from "@views/top-bar/settings/working-conditions/BankDetails";
export default {
  props: {
    club: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    BankDetails,
    Timetable: () => import('./Timetable'),
    CancellationCondition: () => import('./CancellationCondition'),
    ClubServices: () => import('./ClubServices'),
  },
  methods: {
    getTimeTableCols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1518 && width >= 962) {
        return 5;
      } else if (width < 962) {
        return 12
      } else {
        return 3;
      }
    },
    getCancellationConditionsCols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1518) {
        return '';
      } else return '';
    },
  }
}
</script>
<style scoped>
.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.card .card-body {
  padding-top: 12px;
  padding-left: 35px;
  padding-right: 25px;
  padding-bottom: 12px;
  background-color: transparent;
}

.card .card-header {
  background-color: #fff;
  padding-top: 5px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
